<script context="module">
  import Toast, { Toaster } from 'common/Toast.svelte'
</script>

<script>
  import { onMount } from 'svelte'

  onMount(() => {
    document
      .querySelectorAll('script[data-toast]')
      .forEach(({ innerText: message, dataset: { toast } }) => {
        $Toaster[toast](JSON.parse(message))
      })
  })
</script>

<Toast bind:toast={$Toaster} />
